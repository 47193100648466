.OrgView{
    background-color: #99f3f3;
}

.selected{
    border-bottom: 0.01rem solid red;
}

.section{
    width: 33%
}

.newReviewSection{
    background-color: #f2e8de78;;
}

.org-dets{
    justify-content: space-evenly;
}

#static-map{
    height: 10rem;
    width: 25%;
}

.small-dets{
    width: 40%;
}

@media screen and (max-width:800px){
    #static-map{
        width: 90%;
    }
}

@media screen and (max-width:700px) {
        .org-dets{
            justify-content: flex-start;
        }
        #static-map{
            width:90%;
        }
        
}

@media screen and (max-width:500px) {
    .small-dets{
        width: 100%
    }
}
