/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
/* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
#map {
    height: 100%;
}


.controls {
    background-color: #fff;
    border-radius: 0.25rem;
    border: 0.25rem solid transparent;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 300;
    height: 1.8rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    outline: none;
    padding: 0 1rem 0 1rem;
    text-overflow: ellipsis;
    width: 50%;
}

.controls:focus {
    border-color: #4d90fe;
}

.title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.mapContainer {
    width: 50vw;
    height: 50vh;
}

#submitPlace{
    margin-left: 0.5rem;
    margin-top: 0.2rem;
}

.orgsearchbtn:focus{
    outline: none;
}

@media screen and (max-width:700px) {
    .controls{
        width: 80%;
    }
    .mapContainer{
        width:90vw;
        height: 50vh
    }
}